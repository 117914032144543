// export const usersPermissions=<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M7 4H2V6H4L7.68 13.39L6.25 15.56C6.09 15.81 6 16.11 6 16.42C6 17.29 6.71 18 7.58 18H19V16H8.42L9.1 14H15.55C16.3 14 16.96 13.56 17.26 12.87L20.53 6.09L18.71 5.33L15.55 12H9.36L8.92 11H19V9H8.54L7 4ZM10 22C11.1 22 12 21.1 12 20C12 18.9 11.1 18 10 18C8.9 18 8 18.9 8 20C8 21.1 8.9 22 10 22ZM18 22C19.1 22 20 21.1 20 20C20 18.9 19.1 18 18 18C16.9 18 16 18.9 16 20C16 21.1 16.9 22 18 22ZM10.18 7L8.4 3.38C8.17 2.9 7.64 2.61 7.09 2.69L5.21 2.98C4.75 3.05 4.41 3.41 4.38 3.87L4.23 6.5H2.75L1.74 4.33C1.61 4.08 1.36 3.94 1.1 3.94H0.5V5H1.1L2.1 7H2.55L3.27 8.61L5 12.43L5.61 14H5.58C5.79 14 5.99 13.92 6.13 13.78L9.07 10.79L9.44 7H10.18ZM5.18 10.45L6.53 8H7.27L5.91 10.45H5.18Z" fill="currentColor"/>
// </svg>


export const usersPermissions=<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  width="16"
  height="16"
>
  <circle cx="8" cy="8" r="4" />
  <path d="M2 22c0-4 6-6 12-6" fill="currentColor" />

  <path d="M17 11l3.5-2.5a1 1 0 0 1 1.5.9V16a3 3 0 0 1-1.4 2.5l-3.5 2.5-3.5-2.5A3 3 0 0 1 13 16v-6.6a1 1 0 0 1 1.5-.9L17 11z" />
</svg>




