import CustomCard from "../CustomCard";
import { Badge, Box, MenuItem, Stack } from "@mui/material";
import { headerContent } from "../CustomTexts";
import globalColors from "../../globalColors";
import { ArrowRight, expand } from "../../assets/svgs";
import CustemListItem from "../CustemListItem";
import CustomIconButton from "../CustomIconButton";
import { store } from "../../store/store";
import {
  ModuleDetails,
  allModules,
  allStatus,
  allStatusInClusterQuestion,
  checkPermisions,
  getNextModule,
  structureProps,
} from "../../store/slices/constants/staticContents";
import { setExamStructure, setExpand } from "../../store/slices/exams";
import { formatStructureData } from "../../pages/exams";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import CustomTextField from "../CustomTextfield";
import { useEffect, useState } from "react";
import { fetchListData } from "../../store/slices/exams/thunks/fetchListData";
import { allApis } from "../../request/config";
const expandButtonCss = {
  background: globalColors.primary.pureWhite,
  position: "absolute",
  top: "40px",
  borderTop: "1px solid #C8C8C8",
  borderBottom: "1px solid #C8C8C8",
  borderRadius: "0 15px 15px 0",
  borderRight: "1px solid #C8C8C8",
  writingMode: "vertical-rl",
  boxShadow: "4px 0px 6px 0px #00000040",
  padding: "10px 3px 10px",
};
export const ExpandButton = () => {
  return (
    <CustomIconButton
      onClick={() => {
        store.dispatch(setExpand());
      }}
      sx={{
        ...expandButtonCss,
        left: "-8px",
      }}
      icon="Expand"
      content={expand}
      // {
      //   <Stack sx={{ ...rowVerticalCenter }} spacing={"4px"}>
      //     <Typography
      //       fontStyle={"normal"}
      //       fontWeight={400}
      //       fontSize={"0.7rem"}
      //       lineHeight={"1rem"}
      //       color={"currentColor"}
      //       textTransform={"capitalize"}
      //     >
      //       {}
      //     </Typography>
      //     {expand}
      //   </Stack>
      // }
    />
  );
};
export const CollapseButton = () => {
  return (
    <CustomIconButton
      onClick={() => {
        store.dispatch(setExpand());
      }}
      sx={{
        ...expandButtonCss,
        right: "-8px",
        transform: "rotate(-180deg)",
      }}
      icon={"Collapse"}
      content={expand}
    />
  );
};
function ExpandViewCard({
  item,
  singleCard,
}: {
  item: structureProps;
  singleCard?: true;
}) {
  const exams_structure: structureProps = useAppSelecter(
    (state: ObjectValues) => state.exams.exams_structure
  );
  const is_expanded: boolean = useAppSelecter(
    (state: ObjectValues) => state.exams.is_expanded
  );
  const userSlice: ObjectValues = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );  
  const formatted_exams_structure: structureProps[] = useAppSelecter(
    (state: ObjectValues) => state.exams.formatted_exams_structure
  );


  const dispatch = useAppDispatch();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const getGoalId = () => {
    return formatted_exams_structure.find((item) => item.title === allModules[1])?.selected_row?.id

  };
  useEffect(() => {
    if (item.fetchAgain === true) {
      dispatch(
        fetchListData({
          api: `${allApis[item.title].list((item.title === allModules[6] || item.title === allModules[7]) ?
            item.selectedTabInClusterQuestion?.label.toLowerCase() : 
            item.selectedTab?.label.toLowerCase(),
            item.parentDetails?.id ?? "",
            item.title === allModules[2] ||
              item.title === allModules[3] ||
              item.title === allModules[4] ||
              item.title === allModules[5] ? getGoalId() :
              ""
          )}`,
          method: "GET",
          payload: {},
          token: `${item.title}`,
          extra: item,
        })
      );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.fetchAgain]);
  const [tableData, setTableData] = useState<ObjectValues>({
    actual: [],
    copy: [],
    all_status: [],
  });
  useEffect(() => {
    // ModuleDetails[item.title].arrange_path
    const isAllStatusTab = item.selectedTab?.label.toLowerCase() === "all";
    const moduleTitle = item.title;
        let filterLabel: string | undefined = "all";
        if(moduleTitle == "goals" || moduleTitle == "exams") {
            filterLabel = item.selectedTab?.label.toLowerCase();
        } else if (moduleTitle == "clusters" || moduleTitle == "questions") {
            filterLabel = item.selectedTabInClusterQuestion?.label.toLowerCase();
        } 

        const filteredData = ModuleDetails[item.title]?.formatList(item.list)?.filter((s) =>
              (isAllStatusTab && moduleTitle != "clusters")
                ? true // If All Status tab, don't filter by status
                : (s.cluster?.status ?? s.status) ===
                  filterLabel
            )
            ?.filter((it) => it.is_active)
        const sectionsData = ModuleDetails[item.title]?.formatList(item.list)?.filter((it) => it.is_active)

        setTableData(
            ModuleDetails[item.title]?.arrange_path
                ? {
                    all_status: (item.title == 'sections' || item.title == 'unit' 
                    || item.title == 'topics' || item.title == 'subtopics')  ? sectionsData : filteredData,
                    actual: (item.title == 'sections' || item.title == 'unit' 
                      || item.title == 'topics' || item.title == 'subtopics') ? sectionsData : filteredData,
                    copy: (item.title == 'sections' || item.title == 'unit' 
                      || item.title == 'topics' || item.title == 'subtopics') ? sectionsData : filteredData,
                }
                : {
                    all_status: (item.title == 'sections' || item.title == 'unit' 
                      || item.title == 'topics' || item.title == 'subtopics') ? sectionsData : filteredData,
                    actual: (item.title == 'sections' || item.title == 'unit' 
                      || item.title == 'topics' || item.title == 'subtopics') ? sectionsData : filteredData,
                    copy: (item.title == 'sections' || item.title == 'unit' 
                      || item.title == 'topics' || item.title == 'subtopics') ? sectionsData : filteredData,
                }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return checkPermisions(
    userSlice?.user_permissions ?? [],
    "view",
    "exam",
    "syllabus"
  ) ? (
    <CustomCard
      sx={{
        maxWidth: "250px",
        minWidth: singleCard ? "150px" : "",
        p: "10px 0",
        boxShadow: "none",
        position: "relative",
        flex: "8",
      }}
      content={
        <Stack spacing={"15px"}>
          {headerContent({
            size: "0.8rem",
            text: item.title,
            sx: {
              p: "0 10px 10px",
              borderBottom: `1px solid ${globalColors.primary.borderColor}`,
              color: globalColors.primary.normalText,
              textTransform: "capitalize",
            },
            icons: [
              //   {
              //     content: iconText({ icon: Addicon }),
              //     variant: "outlined",
              //     onClick: () => {
              //       store.dispatch(
              //         setExamStructure(
              //           formatStructureData(
              //             exams_structure,
              //             { create: true },
              //             item.title
              //           )
              //         )
              //       );
              //     },
              //     sx: { p: "5px" },
              //   },
            ],
          })}
          <Stack spacing={"5px"} minWidth={"125px"} p="0 5px">
            {ModuleDetails[item.title]?.isTab && (
              <CustomTextField
                required={true}
                autoComplete="off"
                value={(item?.title == "clusters" || item?.title == "questions") ? 
                  item.selectedTabInClusterQuestion?.label : item.selectedTab?.label}
                sx={{
                  width: "100%",
                  marginBottom: "15px !important",
                  textTransform: "capitalize",
                  "& .MuiInputBase-root": { padding: "5px 7px !important" },
                  "& .MuiInputBase-input": { padding: "0 !important" },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        margin: "1px 0 0 2px",
                        borderRadius: "10px",
                      },
                    },
                  },
                }}
                id="outlined-select-currency-native"
                select
                // disabled={true}
                variant="outlined"
                onChange={(e) => {
                  store.dispatch(
                    setExamStructure(
                      formatStructureData(
                        exams_structure,
                        {
                          selectedTab: allStatus.find(
                            (it) => it.label === e.target.value
                          ),
                          selectedTabInClusterQuestion: allStatusInClusterQuestion.find(
                            (it) => it.label === e.target.value
                          ),
                          fetchAgain: true,
                          loading: true,
                        },
                        item.title
                      )
                    )
                  );
                }}
              >
                {item.title == "clusters" || item.title == "questions" ? allStatusInClusterQuestion?.map(({ label }, i) => (
                  <MenuItem
                    key={`${label}-${i}`}
                    value={label}
                    id={label}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {label}
                  </MenuItem>
                )) : allStatus?.map(({ label }, i) => (
                  <MenuItem
                    key={`${label}-${i}`}
                    value={label}
                    id={label}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
            <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            { tableData?.actual.length > 0 ?
            <Box sx={{ fontSize: "14px", fontWeight: "bold", marginRight: "20px" }}>
    Total count
  </Box>
  :<></>}
            <Badge
    badgeContent={tableData?.actual.length > 0 ? tableData?.actual?.length : 0} // Total count of list items
    color="primary"
    overlap="circular"
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  >
  </Badge>
  </Box>
            {item.loading
              ? ""
              : <Box
              sx={{
                maxHeight: "500px", // Adjust the height as per your requirement
                overflowY: "auto", // Enable vertical scrolling
                scrollbarWidth: "thin", // Adjust the scrollbar width
              }}
            >
              {tableData.actual &&
                tableData.actual.length > 0 &&
                tableData.actual.map((li:any, i: number) => {
                  return (
                    <CustemListItem
                      key={`${li.name}-${i}`}
                      ListItemButtonsx={{maxHeight:"fit-content"}}
                      keyId={`${li.name}`}
                      isSelected={li.id === item?.selected_row?.id}
                      selectedColor={globalColors.primary.lightGrey}
                      onClick={() => {
                        store.dispatch(
                          setExamStructure(
                            formatStructureData(
                              exams_structure,
                              {
                                selected_row: li,
                                dockedTable: false,
                                dockedList: is_expanded?item.dockedList: true,

                                child: item.child
                                  ? {
                                      ...item.child,
                                      fetchAgain: true,
                                      loading: true,
                                      parentDetails: li,
                                      selectedTabInClusterQuestion: allStatusInClusterQuestion[0],
                                    }
                                  : {
                                      fetchAgain: true,
                                      title: getNextModule(item.title),
                                      parentDetails: li,
                                      list: [],
                                      child: null,
                                      loading: true,
                                      create: false,
                                      edit: false,
                                      selected_row: null,
                                      dockedTable: true,
                                      dockedList: false,
                                      selectedTab: allStatus[0],
                                      selectedTabInClusterQuestion: allStatusInClusterQuestion[0],
                                    },
                              },
                              item.title
                            )
                          )
                        );
                      }}
                      direction="reverse"
                      primary={`${i + 1}. ${
                        li.cluster?.name ||
                        (li.translation && li.translation[0]?.text
                          ? li.translation[0]?.text
                          : li.name)
                      }`}
                      icon=
                        {ArrowRight}
                    />
                  );
                })}
                </Box>
                }
          </Stack>
        </Stack>

      }
    />
  ) : (
    <></>
  );
}

export default ExpandViewCard;
