import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Breadcrumbs,
  Link,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

const InternalUsers = () => {
    const navigate = useNavigate();
  const internalUsers = [
    { id: 1, name: 'Afajal Meman', email: 'Afajal.meman@example.com', phone: '123-456-7890' },
    { id: 2, name: 'Rohit Kumar', email: 'Rohit.kumar@example.com', phone: '987-654-3210' },
  ];

  return (
    <Paper style={{ width: '99%', height: "90vh", overflow: 'hidden' }}>
    <div style={{ padding: '16px' }}>
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '16px' }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/usersPermissions')}
          style={{ cursor: 'pointer' }}
        >
          Users and Permissions
        </Link>
        <Link
          underline="hover"
          color="inherit"
          style={{ cursor: 'pointer' }}
        >
        <Typography color="text.primary">Internal Users</Typography>
        </Link>
      </Breadcrumbs>
      <TextField
        variant="outlined"
        placeholder="Search Internal Users"
        style={{ width: '30%' , marginBottom: '16px' }}
        margin="normal"
      />
      <Button variant="contained" color="primary" style={{ margin: '16px' ,float:"right"}}>
        Add New
      </Button>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {internalUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </Paper>
  );
};

export default InternalUsers;
