import React, { useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Paper,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Define the type for module permissions
interface Permission {
  id: number;
  module: string;
  read: boolean;
  create: boolean;
  edit: boolean;
}

const Permissions: React.FC = () => {
    const navigate = useNavigate();
  // State for managing permissions
  const [permissions, setPermissions] = useState<Permission[]>([
    { id: 1, module: 'Exams and Topical Mastery', read: false, create: false, edit: false },
    { id: 2, module: 'Mock Tests', read: false, create: false, edit: false },
    { id: 3, module: 'Content Feedback', read: false, create: false, edit: false },
    { id: 4, module: 'Order Management', read: false, create: false, edit: false },
    { id: 5, module: 'Users and Permissions', read: false, create: false, edit: false },
  ]);

  // Handle checkbox change
  const handleCheckboxChange = (id: number, permissionType: keyof Permission) => {
    setPermissions((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [permissionType]: !item[permissionType] } : item
      )
    );
  };

  // Handle save action
  const handleSave = (id: number) => {
    const modulePermissions = permissions.find((item) => item.id === id);
    console.log('Saved Permissions:', modulePermissions); // Replace with API call
    alert(`Permissions saved for ${modulePermissions?.module}`);
  };

  return (
    <Paper style={{ width: '99%', height: "90vh", overflow: 'hidden' }}>
    <div style={{ padding: '16px' }}>
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '16px' }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/usersPermissions')}
          style={{ cursor: 'pointer' }}
        >
          Users and Permissions
        </Link>
        <Link
          underline="hover"
          color="inherit"
          style={{ cursor: 'pointer' }}
        >
        <Typography color="text.primary">Permissions</Typography>
        </Link>
      </Breadcrumbs>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Module</TableCell>
              <TableCell align="center">Read</TableCell>
              <TableCell align="center">Create</TableCell>
              <TableCell align="center">Edit</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.module}</TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.read}
                    onChange={() => handleCheckboxChange(row.id, 'read')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.create}
                    onChange={() => handleCheckboxChange(row.id, 'create')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    checked={row.edit}
                    onChange={() => handleCheckboxChange(row.id, 'edit')}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleSave(row.id)}
                    startIcon={<Check />}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </Paper>
  );
};

export default Permissions;
