import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allApis } from "../../../request/config";
import apiRequest from "../../../request/apiRequest/apiRequest";
import { ObjectValues } from "../../../pages/login";

// Define the initial state
interface OrderPaymentState {
  orderPaymentList: any[];
  loading: boolean;
  error: string | null;
}

const initialState: OrderPaymentState = {
  orderPaymentList: [],
  loading: false,
  error: null,
};

// create get order list slice
export const fetchOrdersAndPayments = createAsyncThunk(
    "orderPayment/fetchOrdersAndPayments",
    async ({ page, pageSize, search,status,plan_type,modified_at_after, modified_at_before, orderBy}: { page: number; pageSize: number, search: string, status: string,plan_type: string[], modified_at_after?:string, modified_at_before?: string, orderBy?: string | null }) => {
      try {
        const planQuery = plan_type.map(plan => encodeURIComponent(plan)).join(',');
        const query = `${allApis.orderAndPayments()}?page=${page + 1}&page_size=${pageSize}&search=${encodeURIComponent(search)}&order_status=${encodeURIComponent(status)}&plan_type=${planQuery}${modified_at_after ? `&modified_at_after=${encodeURIComponent(modified_at_after)}` : ''}${modified_at_before ? `&modified_at_before=${encodeURIComponent(modified_at_before)}` : ''}${orderBy ? `&ordering=${encodeURIComponent(orderBy)}` : ''}`
        const response: any = await apiRequest({
          api: query,
          method: "GET",
        });
        return {
          results: response.results ?? [],
          count: response.count ?? 0,
        };
      } catch (error: any) {
        throw new Error("Failed to fetch orders and payments list");
      }
    }
  );

const orderAndPaymentSlice = createSlice({
  name: "orderPayment",
  initialState,
  reducers: {
},
  extraReducers: (builder) => {
    builder
      // Handle fetchStaffList
      .addCase(fetchOrdersAndPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrdersAndPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.orderPaymentList = action.payload.results;
      })
      .addCase(fetchOrdersAndPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch orders and payments list";
      })
    
  },
});

export const { } = orderAndPaymentSlice.actions;
export default orderAndPaymentSlice.reducer;
