import React, { useState } from "react";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import * as fns from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { Button } from "@material-ui/core";
// const useStyles = makeStyles((theme) => ({
//     modal: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         //width: "100%",
//         // height: "100vh",
//         backgroundColor: theme.palette.background.paper,
//     },
//     modalContent: {
//         // maxWidth: "500px", // Limit content width
//         // width: "100%", // Full width to enable overflow
//         // overflowX: "auto", // Allow horizontal scrolling for content overflow
//         // backgroundColor: theme.palette.background.paper,
//         width: "100%",
//         maxWidth: "677px",
//         // maxWidth: "640px",
//         // overflowX: "auto",
//         // backgroundColor: theme.palette.background.paper,
//         // scrollbarColor: "red", /* Thumb color and track color */
//         // scrollbarWidth: "thin", /* Thumb width */
//     },
//     datepickerContent: {
//         scrollbarWidth: "thin",
//         // maxWidth: "665px",
//         width: "100%",
//         backgroundColor: "#fff",        
//         overflowX: "auto",
//     }
// }));

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //backgroundColor: theme.palette.background.paper,
    },
    modalContent: {
        //width: "90%",
        //maxWidth: "500px",
        //backgroundColor: theme.palette.background.paper,
        overflowX: "auto",
        padding: theme.spacing(2),
        maxHeight: "90vh", // Ensure content can scroll if needed
        [theme.breakpoints.down('sm')]: {
            //maxWidth: "90%",
            padding: theme.spacing(1),
            maxHeight: "80vh", // Constrain height for mobile view
        },
    },
    datepickerContent: {
        scrollbarWidth:"thin",
        width: "100%",
        backgroundColor: "#fff",
        overflowX: "auto",
        overflowY: "hidden",
        // "-webkit-overflow-scrolling": "touch", // Smooth scrolling on mobile
        maxHeight: "100%", // Ensure height doesn't exceed modalContent
        [theme.breakpoints.down('sm')]: {
            //maxWidth: "100%",
            overflowX: "scroll",
        },
    },
}));

const MuiDateRangePicker = (props: any) => {
    const classes = useStyles();
    const format = props.fomat || "yyyy-MM-dd";


    const getDisplayDateRange = (dateRange: any) => {
        const startDate = dateRange?.startDate
            ? fns.format(dateRange.startDate, format)
            : undefined;

        const endDate = dateRange?.endDate
            ? fns.format(dateRange.endDate, format)
            : undefined;

        return startDate || endDate ? `${startDate} - ${endDate}` : "";
    };

    return (
        <>
            <Modal
                className={classes.modal}
                open={props.open} 
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.modalContent}>
                    <DateRangePicker
                        open={props.open}
                        wrapperClassName={classes.datepickerContent}
                        toggle={props.toggle}
                        closeOnClickOutside={true}
                        onChange={(range: any) => {
                            props.onDateRangeChange(range);
                            // props.toggle();
                        }}
                        initialDateRange={props.dateRange}
                    />
                </div>
            </Modal>
        </>
    );
};

export { MuiDateRangePicker };