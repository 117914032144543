import { useEffect, useState } from "react";
import CustomTextField from "../CustomTextfield";
import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { ObjectValues, inputProp } from "../../pages/login";
import { headerContent, subHeading } from "../CustomTexts";
import CustomAutocomplete from "../CustomAutocomplete";
import CustomCkeditor from "../CustomCkeditor";
import globalColors from "../../globalColors";
import { displayRow, rowCenter, rowSB } from "../../GlobalStyles";
import CustomIconButton from "../CustomIconButton";
import CustomContainedButton from "../CustomContainedButton";
import apiRequest from "../../request/apiRequest/apiRequest";
import { useAppDispatch } from "../../hooks/storeHooks";
import { showSnack } from "../../store/slices/constants";
import CustomDatePicker from "../CustomDatePicker";
import CustomDateTimePicker from "../CustomDateTimePicker";
import moment from "moment";
import { StopScroll, mockTestLanguages } from "../../store/slices/constants/staticContents";
import CopyComponent from "../CopyComponent";
import { cross, rightIcons } from "../../assets/svgs";
import SvgPreview from "../SvgPreview";
import { Checkbox } from "@material-ui/core";
import CustomMultiSelect from "../CustomMultiSelect";
import { inherits } from "util";
import dayjs from "dayjs";
import { Language } from "@mui/icons-material";
import { fetchAllExamsTabData } from "../../store/slices/exams/thunks/fetchContentData";
export interface formCompProps {
  onClear(data?: any): void;
  onSubmit(): void;
  extraFields?: ObjectValues;
  oldSubmittedData?: ObjectValues;
  nextFunc?(): void;
  api: {
    create: any;
    create2?: string;
    edit_method?: any;
    edit: null | string;
  };
  editData?: ObjectValues | undefined;
  header: string;
  inputFormArray: inputProp[];
  inputSteps?: {
    label: string; inputFormArray: inputProp[], id: number,
    formatPayload?(payload: ObjectValues): void;
  }[];

  formatPayload?(payload: ObjectValues, data?: any, edit?: any): void;
  successMessage(res: ObjectValues): string;
  customContent?({ }: any): any
}
function FormComponent(props: formCompProps) {
  const {
    onClear,
    onSubmit,
    api,
    editData,
    header,
    inputFormArray,
    extraFields,
    formatPayload,
    successMessage,
    oldSubmittedData,
    inputSteps, customContent
  } = props;
  const dispatch = useAppDispatch();
  const [selectedStep, setStep] = useState<number>(0);
  const [stepResponse, setStepResponse] = useState<ObjectValues>({});
  const firstStep = inputSteps && inputSteps.length > 0 ? inputSteps.find(s => s.id === selectedStep) : undefined
  const allInputs = firstStep ? firstStep.inputFormArray ?? [] : inputFormArray ?? [];

  const [submittedData, setSubmittedData] = useState<ObjectValues>({});
  useEffect(() => {
    if (oldSubmittedData) {
      setSubmittedData(oldSubmittedData);
    }
  }, [oldSubmittedData]);
  function renderTextField({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    multiline,
    disabled,
    type,
    dataType,
    only_number,
    allowNegative,
    validate,
    validateExp
  }: inputProp) {
    return (
      <CustomTextField
        placeholder={placeholder}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        id={label}
        onKeyDown={(evt) => {
          if (only_number === true) {
            evt.key === "." && evt.preventDefault();
          }
          if (dataType === "number") {
            evt.key === "e" && evt.preventDefault();

            evt.key === "E" && evt.preventDefault();
            if (!allowNegative)
              evt.key === "-" && evt.preventDefault();
            evt.keyCode === 38 && evt.preventDefault();
            evt.keyCode === 40 && evt.preventDefault();
          }
        }}
        onFocus={dataType === "number" ? StopScroll : () => { }}
        variant="outlined"
        error={error}
        type={dataType === "number" ? "number" : "text"}
        helperText={helperText}
        value={submittedData[label] ?? ""}
        maxRows={multiline ?? 1}
        minRows={1}
        multiline={multiline ? true : false}
        onChange={(e) => {
          handleInputChange(label, e.target.value, null, validate, validateExp);
        }}
        onKeyPress={async (ev: any) => {
          if (ev.key === "Enter") {
            ev.preventDefault();

            // submit();
          }
        }}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": { padding: "0" },
          "& .MuiInputBase-input": multiline
            ? {
              padding: "10px 12px",
            }
            : {},
        }}
        InputProps={{
          endAdornment,
        }}
        inputProps={{
          // type: details.visible ? "" : type && "password",
          "data-testid": `${label}-input`, // Test ID for testing purposes
        }}
      />
    );
  }
  const fieldCountAndFinalPayload = () => {
    let submitData: ObjectValues = {}; //new FormData();
    const correctData: any = [];
    allInputs
      .filter((sA) => sA.api)
      .map((ss: inputProp) => {
        const { api, label, not_required, not_send_id, fieldType } = ss;
        // console.log(label, not_required, submittedData)
        if (
          // (singleInput.type === "checkBox" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          // (singleInput.type === "toggle" &&
          //   (submittedData[singleInput.label] === false ||
          //     submittedData[singleInput.label] === true)) ||
          not_required === true ||
          submittedData[label]
        ) {
          correctData.push(label);
          // if (freeSolo) {
          //   if (submittedData[label] && submittedData[label].length > 0&&api) {
          //     submitData[api] = submittedData[label];
          //   }
          // }
          if (api) {
            submitData[api] = not_send_id
              ? submittedData[label]
              : submittedData[`${label}Id`]
                ? submittedData[`${label}Id`] === "-"
                  ? null
                  : submittedData[`${label}Id`]
                : fieldType === "date" && submittedData[label]
                  ? moment(new Date(submittedData[label])).format("YYYY-MM-DD")
                  : fieldType === "dateTime" && submittedData[label]
                    ? moment.utc(new Date(submittedData[label])).format("YYYY-MM-DD HH:mm:ss")
                    //: fieldType === "multiselect" && submittedData[label]
                     // ? submittedData[label].map((s: any) => s.id).join(",")
                      : submittedData[label];
          }
        }
        //   } else {
        //     var errorMsg = this.state.errorMsg;
        //     errorMsg[singleInput.name] = `Wrong ${singleInput.name} !`;
        //     return this.setState({ errorMsg });
        //   }
        // } else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = singleInput.validate
        //     ? singleInput.validate(submittedData) === true
        //       ? ""
        //       : "Password do not match !"
        //     : `Invalid ${singleInput.name} !`;
        //   return this.setState({ errorMsg });
        // }
        // }
        // else {
        //   var errorMsg = this.state.errorMsg;
        //   errorMsg[singleInput.name] = `Please ${singleInput.type === "select" ? "select" : "add"} ${
        //     singleInput.name
        //   } !`;
        //   return this.setState({ errorMsg });
        // }
        return ss;
      });
    //console.log(allInputs)
    return { final: submitData, available: correctData };
  };
  const checkAllFields = () => {
    const { available, final } = fieldCountAndFinalPayload();
   // console.log(allInputs.filter((sA) => sA.api).length, available.length)
    return selectedStep === 1 ? submittedData.que_list ? submittedData.que_list : null : allInputs.filter((sA) => sA.api).length === available.length
      ? final
      : null;
  };

  function renderDropDown({
    label,
    placeholder,
    value,
    error,
    helperText,
    onChange,
    endAdornment,
    listLabel,
    type,
    list,
    disabled,
  }: inputProp) {
    return (
      <CustomTextField
        required={true}
        disabled={submittedData.loader === true ? true : disabled ?? false}
        // error={errorMsg[singleInput.name] ? true : false}
        error={error}
        helperText={helperText}
        autoComplete="off"
        value={submittedData[label] ?? ""}
        sx={{ width: "100%" }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                margin: "1px 0 0 2px",
                borderRadius: "10px", // Customize the border-radius for the dropdown paper
              },
            },
          },
        }}
        // submittedData[singleInput.name]}
        // style={
        //   textFieldStyle
        //     ? textFieldStyle
        //     : textFieldWidth
        //     ? styles.withDivider502
        //     : styles.withDivider50
        // }
        id="outlined-select-currency-native"
        select
        // label={"qq"}
        // singleInput.name.charAt(0).toUpperCase() + singleInput.name.slice(1)}
        // SelectProps={{ disableunderline: "true" }}
        variant="outlined"
        onChange={(e) => {
          // handleInputChange(label, e.target.value);
          if (e.target.value) {
            const id = `${label}Id`;
            // const submitted :any= {};
            // submitted[label] = e.target.value;
            // // var errorMsg = errorMsg;
            // // errorMsg[label] = false;
            // submitted[id] = e.currentTarget.id;
            setSubmittedData((prevDetails) => ({
              ...prevDetails,
              [label]: e.target.value,
              [id]: e.currentTarget?.id ?? "",
              [`${label}-error`]: undefined,
            }));
          }
          //     if (singleInput.onChange) {
          //       singleInput
          //         .onChange(
          //           submittedData,
          //           () => {
          //             this.setState({ loading: true });
          //           },
          //           () => {
          //             this.setState({ loading: false });
          //           }
          //         )
          //         .then((submittedData) => {
          //           this.setState({
          //             submittedData,
          //             errorMsg,
          //           });
          //         });
          //     } else {
          //       this.setState({
          //         submittedData,
          //         errorMsg,
          //       });
          //     }
          //   }
        }}
      >
        {list?.map((item, i) => (
          <MenuItem
            //   disabled={true}
            key={`${item[listLabel ?? "label"]}==${i}`}
            value={item[listLabel ?? "label"]}
            id={item.id ?? item[listLabel ?? "label"]}
            sx={{ textTransform: "capitalize" }}
          >
            {item[listLabel ?? "label"]}
          </MenuItem>
        ))}
      </CustomTextField>
    );
  }
  useEffect(() => {
    if (!oldSubmittedData) {
      const newSubmittedData = submittedData;
      allInputs.map((singleInput) => {
        if (!singleInput.defaultValue && !newSubmittedData[singleInput.label]) {
          if (
            editData &&
            singleInput.api &&
            (editData[singleInput.api] || editData[singleInput.api] === false)
          ) {
            // if (singleInput.type === "checkBox") {
            //   return (newSubmittedData[singleInput.label] = selectedList[singleInput.label] === "Paid" ? true : false);
            // } else if (singleInput.type === "toggle") {
            //   newSubmittedData[singleInput.label] = selectedList[singleInput.label]
            //     ? selectedList[singleInput.label]
            //     : false;
            // } else if (singleInput.type === "Month") {
            //   newSubmittedData[singleInput.label] = selectedList[`${singleInput.label}Month`];
            // } else {
            newSubmittedData[singleInput.label] = editData[singleInput.api];
            newSubmittedData[`${singleInput.label}Id`] = editData[
              `${singleInput.api}`
            ]
              ? editData[`${singleInput.api}`]
              : "";

            return newSubmittedData;
            // }
          }
          // else if (singleInput.defaultValue) {
          //   newSubmittedData[singleInput.label] =
          //     singleInput.type === "toggle"
          //       ? singleInput.defaultValue.value
          //         ? singleInput.defaultValue.value
          //         : false
          //       : singleInput.type === "checkBox" && singleInput.defaultValue.value === false
          //       ? false
          //       : singleInput.defaultValue.value
          //       ? singleInput.defaultValue.value
          //       : "";

          //   newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue.id ? singleInput.defaultValue.id : "";

          //   return newSubmittedData;
          // } else if (singleInput.type === "Month") {
          //   newSubmittedData[singleInput.name] = new Date();
          // } else if (singleInput.type === "checkBox") {
          //   newSubmittedData[singleInput.name] = false;
          // } else if (singleInput.type === "toggle") {
          //   newSubmittedData[singleInput.name] = false;
          // }
          else {
            return (newSubmittedData[singleInput.label] = "");
          }
        }
        return singleInput;
      });
      setSubmittedData(newSubmittedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const setInitialData = (old?: any) => {
    if (!oldSubmittedData) {
      submittedData.Language = ["English", "Hindi"]
      const newSubmittedData = old ? {} : submittedData;
      allInputs.map((singleInput) => {
        if (!newSubmittedData[singleInput.label]) {
          if (singleInput.defaultValue) {
            newSubmittedData[singleInput.label] = singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

            newSubmittedData[`${singleInput.label}Id`] = singleInput
              .defaultValue.id
              ? singleInput.defaultValue.id
              : "";
          } else {
            return (newSubmittedData[singleInput.label] = "");
          }
        }
        return singleInput;
      });

      setSubmittedData(newSubmittedData);
    }
  }
  useEffect(() => {
    setInitialData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line
  const [fileInput_ref, setFileInputRef] = useState<any>({});
  const isValidInput = (value: string, regEx: RegExp) => {
    const regex = regEx
    return regex.test(value);
  };

  const handleInputChange = (
    label: string,
    value: string | number,
    id?: any,
    validate?: boolean,
    validateExp?: RegExp
  ) => {
    if (validate && validateExp && isValidInput(value.toString(), validateExp)) {
      setSubmittedData((prevDetails) => ({
        ...prevDetails,
        [label]: value,
        [`${label}Id`]: id,
        [`${label}-error`]: undefined,
      }));
    }
    if (!!!validate || !!!validateExp) {
      setSubmittedData((prevDetails) => ({
        ...prevDetails,
        [label]: value,
        [`${label}Id`]: id,
        [`${label}-error`]: undefined,
      }));
    }

  };
  // const inputFormToLogin: inputProp[] = [
  //   {
  //     label: "Multiline",
  //     placeholder: "",
  //     value: "",
  //     error: false,
  //     helperText: " c",
  //     endAdornment: "",
  //     multiline: 4,
  //     fieldType: "text",
  //   },
  //   {
  //     label: "TextField",
  //     placeholder: "",
  //     value: "",
  //     error: false,
  //     helperText: " c",
  //     endAdornment: "",
  //     fieldType: "text",
  //   },
  //   {
  //     label: "ck text",
  //     placeholder: "",
  //     value: "",
  //     error: false,
  //     helperText: " c",
  //     endAdornment: "",
  //     fieldType: "cktextEditor",
  //   },
  //   {
  //     label: "Select",
  //     placeholder: "",
  //     value: "ef",
  //     list: [
  //       { label: "1 item", id: "2112", value: "qwdw" },
  //       { label: "2s item", id: "2112", value: " s qwdw" },
  //     ],
  //     error: false,
  //     helperText: " c",
  //     listLabel: "label",
  //     endAdornment: "",
  //     fieldType: "select",
  //   },
  //   {
  //     label: "Auto Complete",
  //     placeholder: "",
  //     value: "ef",
  //     list: [
  //       { label: "1 item", id: "2112", value: "qwdw" },
  //       { label: "2s item", id: "2112", value: " s qwdw" },
  //     ],
  //     error: false,
  //     helperText: " c",
  //     endAdornment: "",
  //     fieldType: "autocomplete",
  //     listLabel: "label",
  //     // freeSolo:true
  //   },
  // ];
  const submit = async (type?: "exit") => {
    const submitData: any = checkAllFields();
    if (submitData !== null) {
      setSubmittedData((pre) => {
        return { ...pre, loader: true, clicked_button_type: type };
      });
      if (extraFields && extraFields.length > 0 && selectedStep !== 1) {
        extraFields.map((sField: { label: string | number; value: any }) => {
          submitData[sField.label] = sField.value;
          return sField;
        });
      }

      // if (this.props.postDataToState) {
      //   if (this.props.checkAllFields && this.props.checkAllFields() === true) {
      //     this.setState({ loading: true });
      //     this.props.postDataToState(submittedData);
      //   }
      // } else {
      // this.setState({ loading: true });
      // if (
      //   (this.props.checkAllFields && this.props.checkAllFields() === "success") ||
      //   !this.props.checkAllFields
      // ) {
      const response: any = await apiRequest({
        api: selectedStep === 1 && api.create2 ? api.create2 : editData && api.edit !== null ? api.edit : api.create,
        payload: formatPayload ? formatPayload(submitData, selectedStep, editData) : submitData,
        method: editData ? selectedStep === 1 ? "PUT" : api.edit_method ?? "PATCH" : "POST",
      });
      if (response === undefined || (response && response.error)) {
        await setSubmittedData((p) => {
          return { ...p, loader: false };
        });
      } else {

        if (onSubmit) {
          await onSubmit();
        }
        if (type) {
          if (selectedStep === 0 && firstStep) {
            // await setSubmittedData((p) => {
            //   return {};
            // });
            // await dispatch(
            //   showSnack({
            //     message: successMessage(response) ?? "",
            //     severity: "success",
            //     open: true,
            //   })
            // );
            setStepResponse(response)
            setStep(1)
            await setSubmittedData((p) => {
              return { ...p, loader: false };
            });
          } else {
            await setSubmittedData((p) => {
              return {};
            });
            // await dispatch(
            //   showSnack({
            //     message: successMessage(response) ?? "",
            //     severity: "success",
            //     open: true,
            //   })
            // );
            await onClear();
          }
        } else {
          if (selectedStep === 1) {
            await setSubmittedData((p) => {
              return {};
            });
            // await dispatch(
            //   showSnack({
            //     message: successMessage(response) ?? "",
            //     severity: "success",
            //     open: true,
            //   })
            // );
            setStepResponse({})
            setStep(0)
          }
          setInitialData("new")
        }

        await dispatch(
          showSnack({
            message: successMessage(response) ?? "",
            severity: "success",
            open: true,
          })
        );
         dispatch(fetchAllExamsTabData())
      }
      // console.log(response, "response");

      //   if (res.error) {
      //     this.setState({
      //       error:
      //         typeof res.data === "string"
      //           ? res.data
      //           : res.data.length > 0
      //           ? res.data
      //               .map((s) => {
      //                 return `${s.field ? `${s.field} :` : ""} ${
      //                   typeof s.message === "string"
      //                     ? s.message
      //                     : s.message.length > 0 && s.message[0] && s.message[0]
      //                 }`;
      //               })
      //               .join(" ,")
      //           : res.data.response
      //           ? res.data.response.data.msg.error
      //             ? res.data.response.data.msg.error
      //             : res.data.response.data.msg
      //           : "Error !",
      //       loading: false,
      //     });
      //   } else {
      //     if (this.props.onEditUpdate) {
      //       this.props.onEditUpdate(res);
      //     }
      //     if (this.props.addApi2 || this.props.secondUpdate) {
      //       this.props
      //         .postData(
      //           this.props.secondUpdate && this.props.secondUpdate.method
      //             ? this.props.secondUpdate.method
      //             : "patch",
      //           this.props.secondUpdate && this.props.secondUpdate.api
      //             ? this.props.secondUpdate.api
      //             : this.props.addApi2.api,
      //           this.props.secondUpdate ? null : res.user_id,
      //           "postdata",
      //           this.props.secondUpdate
      //             ? this.props.formatData
      //               ? this.props.formatData(submittedData, res, "first_api")
      //               : {}
      //             : this.props.addApi2.formatData
      //             ? this.props.addApi2.formatData(submittedData, res)
      //             : {}
      //         )
      //         .then((res2) => {
      //           console.log(res2, "res2");
      //           if (res2.error || res2.status === "error") {
      //             this.setState({
      //               error:
      //                 typeof res2.msg === "string"
      //                   ? res2.msg
      //                   : typeof res2.data === "string"
      //                   ? res2.data
      //                   : res2.data.length > 0
      //                   ? res2.data
      //                       .map((s) => {
      //                         return `${s.field ? `${s.field} :` : ""} ${
      //                           s.message[0] && s.message[0]
      //                         }`;
      //                       })
      //                       .join(" ,")
      //                   : res2.data.response
      //                   ? res2.data.response.data.msg.error
      //                     ? res2.data.response.data.msg.error
      //                     : res2.data.response.data.msg
      //                   : "Error !",
      //               loading: false,
      //             });
      //           } else {
      //             if (this.props.secondUpdate && this.props.secondUpdate.api2) {
      //               this.props
      //                 .postData(
      //                   this.props.secondUpdate && this.props.secondUpdate.method2
      //                     ? this.props.secondUpdate.method2
      //                     : "patch",
      //                   this.props.secondUpdate && this.props.secondUpdate.api2
      //                     ? this.props.secondUpdate.api2
      //                     : this.props.addApi2.api2,
      //                   null,
      //                   "postdata",
      //                   this.props.formatData
      //                     ? this.props.formatData(submittedData, res, null, "second_api")
      //                     : {},
      //                   null,
      //                   "noForwordSlash"
      //                 )
      //                 .then((res21) => {
      //                   console.log(res21, "res21");
      //                   if (res21.error || res21.status === "error") {
      //                     this.setState({
      //                       error:
      //                         typeof res21.msg === "string"
      //                           ? res21.msg
      //                           : typeof res21.data === "string"
      //                           ? res21.data
      //                           : res21.data.length > 0
      //                           ? res21.data
      //                               .map((s) => {
      //                                 return `${s.field ? `${s.field} :` : ""} ${
      //                                   s.message[0] && s.message[0]
      //                                 }`;
      //                               })
      //                               .join(" ,")
      //                           : res21.data.response
      //                           ? res21.data.response.data.msg.error
      //                             ? res21.data.response.data.msg.error
      //                             : res21.data.response.data.msg
      //                           : "Error !",
      //                       loading: false,
      //                     });
      //                   } else {
      //                     this.setState(
      //                       {
      //                         // success: editList ? `${module} Edited` : "",
      //                         dataAdded: editList ? `${module} Edited` : `New ${module} Added`,
      //                         loading: false,
      //                       },
      //                       () => {
      //                         // if (editList) {
      //                           this.props.fetchAgain(res, editList ? `${module} Edited` : `New ${module} Added`);
      //                         // }
      //                       }
      //                     );
      //                   }
      //                 });
      //             } else {
      //               this.setState(
      //                 {
      //                   // success: editList ? `${module} Edited` : "",
      //                   dataAdded: editList ? `${module} Edited` : `New ${module} Added`,
      //                   loading: false,
      //                 },
      //                 () => {
      //                   // if (editList) {
      //                     this.props.fetchAgain(res, editList ? `${module} Edited` : `New ${module} Added`);
      //                   // }
      //                 }
      //               );
      //             }
      //           }
      //         });
      //     } else {
      //       this.setState(
      //         {
      //           // success: editList ? `${module} Edited` : `New ${module} Added`,
      //           dataAdded: editList ? `${module} Edited` : `New ${module} Added`,
      //           loading: false,
      //         },
      //         () => {
      //           // if (editList) {
      //             this.props.fetchAgain(res, editList ? `${module} Edited` : `New ${module} Added`);
      //           // }
      //         }
      //       );
      //     }
      //   }
      // });
      //   }
      // }
    }
  };
  const oneContentWidth = {
    maxWidth: "400px",
    width: oldSubmittedData ? "100%" : "80%",
  };
  const fullWidthSx = { width: "100%" }
  const singleForm: any = (input: inputProp, i: number) => {
    const headingContent = subHeading({
      text: `${input.label} ${input.not_required === true ? "" : "*"}`,
    });
    const sx = input.fullWidth ? fullWidthSx : oneContentWidth;
    switch (input.fieldType) {
      case "image":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx
            }}
          >
            {headingContent}
            <Box
              sx={{
                border: `1px solid ${globalColors.primary.grey}`, // mostLightGrey, // sets the border color to purple
                borderRadius: "10px",
                padding: "0.69px 0.69px 0.69px 12px",
                color: globalColors.primary.black,
                ...rowSB,
              }}
            >
              {subHeading({
                text:
                  submittedData[input.label] && submittedData[input.label].name
                    ? submittedData[input.label].name
                    : "",
                parentSx: { flex: 8, width: "calc(100% - 116px)" },
                sx: {
                  whiteSpace: "nowrap",
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                },
              })}{" "}
              <input
                accept="image"
                ref={(ref) => {
                  setFileInputRef(ref);
                }}
                multiple
                id="files"
                style={{
                  visibility: "hidden",
                  width: "0",
                  maxHeight: "11px",
                }}
                type="file"
                onChange={(e: {
                  preventDefault: () => void;
                  stopPropagation: () => void;
                  target: { files: any };
                }) => {
                  e.preventDefault();
                  e.stopPropagation();

                  let fileObj = [];
                  const fileA = [];
                  fileObj.push(e.target.files);
                  for (let i = 0; i < fileObj[0].length; i++) {
                    fileA.push(fileObj[0][i]);
                  }
                  handleInputChange(input.label, fileA[0]);
                }}
              />
              <label htmlFor="files" className="btn">
                <Box
                  sx={{
                    backgroundColor: globalColors.primary.black, // You can customize the background color here
                    color: globalColors.primary.white,
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "0.875rem !important",
                    borderRadius: "8px",
                    lineHeight: "normal",
                    cursor: "pointer",
                    padding: "9px 10px",
                  }}
                >
                  Choose image
                </Box>
              </label>
            </Box>
          </Stack>
        );
      case "text":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderTextField(input)}
          </Stack>
        );
      case "select":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            {renderDropDown(input)}
          </Stack>
        );
      case "date":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <CustomDatePicker
              {...input}
              value={submittedData[input.label] ?? null}
              disabled={
                submittedData.loader === true ? true : input.disabled ?? false
              }
              onChange={(val: any) => {
                handleInputChange(input.label, val);
              }}
            />
          </Stack>
        );
      case "dateTime":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <CustomDateTimePicker
              {...input}
              value={submittedData[input.label] ?? null}
              disabled={
                submittedData.loader === true ? true : input.disabled ?? false
              }
              onChange={(val: any) => {
                handleInputChange(input.label, dayjs(val).format("YYYY-MM-DD HH:mm:ss"));
              }}
            />
          </Stack>
        );
      // ClusterInput
      case "autocomplete":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
            }}
          >
            {headingContent}
            <CustomAutocomplete
              {...input}
              value={
                input.list?.find(
                  (s) =>
                    s[input.listLabel ?? "label"] === submittedData[input.label]
                ) ?? submittedData[input.label] ? { [input.listLabel ?? "label"]: submittedData[input.label] } : null
              }
              onChange={async (val: any) => {
                handleInputChange(
                  input.label,
                  val ? val[input.listLabel ?? "label"] : null,
                  val ? val.id : undefined
                );
                if (val && val.id && input.onUpdate) {
                  const data = await input.onUpdate(val);
                  if (data) {
                    setSubmittedData((prevDetails) => ({
                      ...prevDetails,
                      ...data,
                      // [label]: value,
                      // [`${label}Id`]: id,
                      // [`${label}-error`]: undefined,
                    }));
                  }

                }
              }}
              disabled={
                submittedData.loader === true ? true : input.disabled ?? false
              }
            />
          </Stack>
        );
      case "multiselect":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              ...sx,
              // height: "100px",
            }}
          >
            {headingContent}
            <CustomMultiSelect {...input} value={['English', 'Hindi']} onChange={(val: any) => handleInputChange(input.label, val)} />
          </Stack>
        );
      case "cktextEditor":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              // height: "100px",
            }}
          >
            {headingContent}
            <Box>
              {" "}
              <CustomCkeditor
                {...input}
                onChange={(val: any) => handleInputChange(input.label, val)}
              />
            </Box>
          </Stack>
        );
      case "extra":
        return (
          <Stack
            spacing={"5px"}
            sx={{
              marginTop: i === 1 ? "0 !important" : "",
              marginBottom: "20.91px",
              ...sx,
              // height: "100px",
            }}
          ></Stack>
        );
      default:
        return <Grid item xs={singlexs} display="flex" key={"default"}></Grid>;
    }
  };
  const singlexs =
    !oldSubmittedData && allInputs.filter((it) => it.pinned).length > 0
      ? 4
      : 6;
  const allFormComp: any = (list: inputProp[]) => {
    const getBorderStyle = (index: number, total: number) => {
      if (index > 9 || index === total - 1) {
        return {};
      }

      const isFirstRow = index < 3; // Assuming 2 columns per row, adjust accordingly
      const isLastRow = index >= total - 4 && index < total - 1; // Adjust accordingly
      const isLeftColumn = index % 3 === 0;
      const isRightColumn = index % 3 === 2;

      const isTopLeft = index === 0;
      const isTopRight = index === 2;
      const isBottomLeft = index === 6; //total - (total % 3 === 0 ? 3 : total % 3);
      const isBottomRight = index === 8;
      //line dotted

      const borderTop = isFirstRow ? '3px dotted grey' : 'none';
      const borderBottom = isLastRow ? '3px dotted grey' : 'none';
      const borderLeft = isLeftColumn ? '3px dotted grey' : 'none';
      const paddingLeft = isLeftColumn ? '10px' : '0px';
      const paddingBottom = isLastRow ? '10px' : '0px';
      const paddingRight = isRightColumn ? '40px' : '0px';
      const borderRight = isRightColumn ? '3px dotted grey' : 'none';
      // const borderRadius = isLeftColumn ? '10px 0px 0px 10px' : isRightColumn ? '0px 10px 10px 0px' :
      // '0px';

      let borderRadius = '0px';
      if (isTopLeft) borderRadius = '10px 0px 0px 0px';
      if (isTopRight) borderRadius = '0px 10px 0px 0px';
      if (isBottomLeft) borderRadius = '0px 0px 0px 10px';
      if (isBottomRight) borderRadius = '0px 0px 10px 0px';

      return {
        borderTop,
        borderBottom,
        borderLeft,
        paddingLeft,
        paddingBottom,
        paddingRight,
        borderRight,
        borderRadius
      };
    };
    const fieldSetNames = new Set(list.map((it) => it?.fieldSetName));

    return list?.map((input: inputProp, i: number) => {
      const borderStyle = getBorderStyle(i, list.length);
      return (
        <Grid
          item
          xs={input.fullWidth ? 12 : singlexs}
          display="flex"
          key={`${input.label}=${i}`}
          sx={
            oldSubmittedData ? { maxWidth: "calc(50% - 7.5px) !important" } : {}
          }
          {...input.grid}
          style={input.fieldSetName != undefined ? borderStyle : {}}
        >
          {singleForm(input, i)}
        </Grid>
      );
    });
  };
  return (
    <Stack spacing={"13px"} height="100%" justifyContent={"space-between"}>
      <Stack spacing={selectedStep !== 0 && customContent ? "10px" : "20px"} sx={{ overflow: "hidden", flex: "8" }}>
        <Box
          sx={{
            ...rowSB,
            borderBottom: `1px solid ${globalColors.primary.borderColor}`,
            alignItems: "center",
            p: "0 10px 10px",
          }}
        >
          {inputSteps && inputSteps.length > 0 ? (
            <Box sx={{ ...displayRow, gap: "55px" }}>
              {inputSteps.map(({ label }, i: number) => {
                const activeStep = selectedStep === i;
                const pastStep = selectedStep > i;
                return (
                  <Box
                    sx={{

                      ...displayRow,
                      gap: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        height: "23px",
                        width: "23px",
                        border: pastStep ? "" : `2px solid ${activeStep ? globalColors.primary.green :
                          "#CDCDCD"
                          }`,
                        ...rowCenter,
                        fontSize: "0.9rem",
                        fontWeight: 500
                        , background: pastStep ? globalColors.primary.green : ""
                      }}
                    >
                      {pastStep ? rightIcons
                        : i}
                    </Box>
                    {headerContent({
                      size: "1.1rem",
                      text: label,
                      weight: "500",
                      sx: {
                        textTransform: "capitalize",
                        width: "auto", color: activeStep || pastStep
                          ? globalColors.primary.black
                          : globalColors.primary.normalText,
                      },
                      icons: [],
                    })}
                  </Box>
                );
              })}
            </Box>
          ) : (
            headerContent({
              size: "1.1rem",
              text: oldSubmittedData
                ? "Create a Copy"
                : `${editData ? "Edit" : `Add ${header === "clusters" ? "" : "New"}`} ${header}`,
              weight: "500",
              sx: {
                color: globalColors.primary.black,
                textTransform: "capitalize",
                width: oldSubmittedData ? "100%" : "auto",
              },
              icons: oldSubmittedData
                ? [
                  {
                    content: <SvgPreview svg={cross} />,
                    onClick: onClear,
                    sx: { padding: "0" },
                  },
                ]
                : [],
              // [{ content: "Copy and Edit", onClick: () => {} }],
            })
          )}
          {oldSubmittedData ? (
            ""
          ) : (
            <CopyComponent
              formProps={{ ...props, oldSubmittedData: submittedData }}
            />
          )}
        </Box>
        {selectedStep !== 0 && customContent ? customContent({
          submittedData: submittedData,
          res: stepResponse, onClear: () => { }, onSubmit: (val: any) => {
            setSubmittedData((p) => { return { ...p, que_list: val } })
          }
        }) :
          <Stack
            sx={{ overflowY: "auto", flex: "8" }} className="scrollBluePrimarythin"
            direction={"row"} spacing={"10px"}>
            <Grid
              container
              width="100%"
              key={"FormComponent"}
              rowSpacing={1}
              columnGap={oldSubmittedData ? "15px" : ""}
            >
              {allFormComp(allInputs)}
            </Grid>
          </Stack>
        }
      </Stack>
      <Box sx={{ width: "100%", ...rowSB }}>
        <CustomIconButton
          key="cancel"
          variant="outlined"
          content="cancel"
          sx={{ minWidth: "180px" }}
          onClick={() => {
            onClear();
          }}
        />

        <Box>
          {!editData && !oldSubmittedData && (!firstStep || (selectedStep !== 0 && firstStep)) && (
            <CustomIconButton
              key="Save"
              disabled={
                checkAllFields() === null ? true : submittedData.loader ?? false
              }
              variant="outlined"
              content="Save and Create New"
              sx={{
                minWidth: "180px",
                // padding: "2px 16px",
                marginRight: "15px",
              }}
              loading={
                submittedData.loader && !submittedData.clicked_button_type
                  ? submittedData.loader
                  : undefined
              }
              onClick={() => {
                submit();
              }}
            />
          )}
          <CustomContainedButton
            otherProps={{
              disabled:
                checkAllFields() === null
                  ? true
                  : submittedData.loader ?? false,
              onClick: () => {
                submit("exit");
              },
              sx: { minWidth: "180px" },
            }}
            loading={
              submittedData.loader && submittedData.clicked_button_type
                ? submittedData.loader
                : undefined
            }
            content={selectedStep === 0 && firstStep ? "Save and Next" : oldSubmittedData ? "Create" : "Save and Exit"}
          />
        </Box>
      </Box>
    </Stack>
  );
}

export default FormComponent;
