import React from 'react';
import { Card, CardActionArea, Typography, Grid, Paper, Box, Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { People, Security } from '@mui/icons-material'; 

const UserPermissions = () => {
  const navigate = useNavigate();

  return (
    <Paper style={{ width: '99%', height: "90vh", overflow: 'hidden' }}>
    <div style={{padding:"16px", display: 'flex', flexDirection: 'column' }}>
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: '16px' }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/usersPermissions')}
          style={{ cursor: 'pointer' }}
        >
          Users and Permissions
        </Link>
        {/* <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate('/internalUsers')}
          style={{ cursor: 'pointer' }}
        >
        <Typography color="text.primary">Internal Users</Typography>
        </Link> */}
      </Breadcrumbs>
      {/* <Typography variant="h6" gutterBottom>
        Users and Permissions
      </Typography> */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Card style={{borderRadius: '10px'}}>
            <CardActionArea sx={{ minWidth: 250, height: 100, borderRadius: '10px',display:"flex",alignItems: 'center' }}
            onClick={() => navigate('/internalUsers')}>
            <Box display="flex" alignItems="center" gap={3}>
              <People fontSize="large" color="primary" /> {/* Internal Users Icon */}
              <div style ={{display:"flex",flexDirection:"column"}}>
              <div style={{fontSize:"14px"}}>Internal Users</div>
              <div style={{fontSize:"12px",color:"grey"}}>Add new users to the portal</div>
              </div>
            </Box>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card style={{borderRadius: '10px'}}>
            <CardActionArea sx={{ minWidth: 250, height: 100, borderRadius: '10px',display:"flex",alignItems: 'center'}}
             onClick={() => navigate('/permissions')}>
            <Box display="flex" alignItems="center" gap={3}>
              <Security fontSize="large" color="primary" /> {/* Access Area Icon */}
              <div style ={{display:"flex",flexDirection:"column"}}>
              <div style={{fontSize:"14px"}}>Permissions</div>
              <div style={{fontSize:"12px",color:"grey"}}>Give permissions to the users</div>
              </div>
            </Box>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
    </Paper>
  );
};

export default UserPermissions;
