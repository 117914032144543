import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ObjectValues } from "../../pages/login";
import { useAppSelecter } from "../../hooks/storeHooks";
import globalColors from "../../globalColors";
import { breadcrumbData, breadcrumbDataForExams } from "../../store/slices/constants";
import { iconText } from "../CustomTexts";

export default function CustomBreadcrumbsForMock() {
  const constantsData = useAppSelecter(
    (state: ObjectValues) => state.exams
  );
  const breadcrumbData = constantsData.breadcrumb;

  const staticLabels = ["", "Exam", "Goal", "Mock Test Series", "Mock Test","Section","Question"];

  const formattedBreadcrumbData = breadcrumbData
  //.filter((item: any) => item.label) // Exclude entries without a label
  .map((item: any, index: any) => ({
    ...item,
    staticLabel: staticLabels[index] || "Other", // Assign static label or fallback
  }));
  console.log(formattedBreadcrumbData, breadcrumbData);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        p: "5px 10px 0 15px",
        alignItems:"flex-end",
        ".MuiBreadcrumbs-separator": {
          fontSize: "0.75rem",
          color: globalColors.primary.normalText,
          margin: "0 10px",
        },
      }}
    >
      {formattedBreadcrumbData.map(
        ({ label, icon, onClick ,disabled,staticLabel}: breadcrumbData, i: number) => {
          console.log(icon);
          // const first = i === 0;
          const last = i === formattedBreadcrumbData.length - 1||disabled;
          const displayText = `${staticLabel} (${label})`;
          return iconText({
            key:`${label}-${i}`,
            // iconsx:"2px",
            // iconsx:!last&&{marginBottom:"4px"},
            text: i == 0 ? "" : displayText ?? "",
            icon: icon ?typeof icon === 'function'?icon({height:"14px"}):icon: "",
            sx: {
              cursor: !onClick||last ? "" : "pointer",
              color:!last? globalColors.primary.purple: globalColors.primary.normalText,
              // first ? globalColors.primary.purple :
            },
            size:"0.75rem",
            onClick: last?()=>{}:onClick,
            classname: last ? "" : "linkStyle-always",
          });
        }
      )}
    </Breadcrumbs>
  );
}
